<template>
    <div class="page bg-white top">
        <el-button type="primary" @click="adjust('add', null)" size="small"
                   v-show="hasPermission('collectionDatabase:columnList:add')"
                   plain icon="el-icon-plus">新增</el-button>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                height="calc(100% - 80px)"
                class="table" ref="multipleTable">
            <el-table-column prop="topicName" show-overflow-tooltip sortable label="栏目名称"></el-table-column>
            <el-table-column prop="image" show-overflow-tooltip sortable label="栏目图片">
                <template slot-scope="scope">
                    <el-image style="width: 100px; height: 100px" :src="scope.row.image" fit="contain"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="sort" show-overflow-tooltip sortable label="排序"></el-table-column>
            <el-table-column fixed="right" width="150" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" plain v-show="hasPermission('collectionDatabase:columnList:edit')"
                               @click="adjust('edit', scope.row)">调整</el-button>
                    <el-button size="mini" type="danger" v-if="!loading" plain v-show="hasPermission('collectionDatabase:columnList:del')"
                               @click="del(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageNo"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!--发布-->
        <el-dialog :title="title" width="50%" :visible.sync="visible">
            <el-form size="small" label-width="120px" :model="inputForm" ref="inputForm">
                <el-form-item label="栏目名称：" prop="topicName"
                              :rules="[{ required: true, message: '请输入栏目名称', trigger: 'blur' }]">
                    <el-input maxlength="15" v-model.trim="inputForm.topicName"></el-input>
                </el-form-item>
                <el-form-item label="栏目图片：" prop="image" :rules="[{ required: true}]">
                    <Upload v-if="visible" :uploadAskObj="uploadRequire"
                            @getDataList="getDataList"></Upload>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input-number v-model="inputForm.sort" :min="1" :max="999"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">取消</el-button>
                <el-button size="small" type="primary" @click="confirmAdjust" v-noMoreClick>确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Upload from "@/components/Upload/Upload"

    export default {
        components: {Upload},
        data() {
            return {
                dataList: [],
                pageNo: 0,
                pageSize: 10,
                total: 0,
                loading: false,
                // 调整
                visible: false,
                imgShow: false,
                title: '',
                method: '',
                inputForm: {
                    id: '',
                    topicName: '',
                    image: '',
                    sort: '',
                },
                // 图片上传要求
                uploadRequire: {
                    list: [],
                    fileType: ['jpg', 'png', 'jpeg'],
                    showFile: true, // 是否显示已上传文件列表
                    count: 1, // 上传文件数量 0表示不限制文件的数量
                },
            }
        },
        mounted() {
            this.refreshList();
        },
        methods: {
            // 获取数据列表
            refreshList() {
                this.loading = true
                this.$axios(this.api.dyyg.pubTopicList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                }, 'get').then(res => {
                    this.dataList = res.data.records
                    this.total = parseInt(res.data.total);
                    this.loading = false
                })
            },
            // 调整 method: add新增，edit调整
            adjust(method, row) {
                this.uploadRequire.list = [];
                this.method = method;
                if (method === 'add') {
                    this.title = `新增栏目`
                    this.imgShow = false
                } else if (method === 'edit') {
                    this.title = '栏目调整'
                    this.imgShow = true
                }
                this.visible = true;
                this.$nextTick(() => {
                    this.$refs.inputForm.resetFields()
                    if (method == 'edit') {
                        this.inputForm = this.recover(this.inputForm, row)
                        this.uploadRequire.list = [{
                            name: '',
                            url: row.image
                        }]
                    }
                })
            },
            // 确定调整
            confirmAdjust() {
                if (this.inputForm.image == '') {
                    this.$message('请上传栏目图片')
                    return
                }
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        if (this.title === '新增栏目') {
                            this.$axios(this.api.dyyg.pubTopicSave, this.inputForm, "post").then((res) => {
                                if (res.status) {
                                    this.visible = false;
                                    this.$message.success('保存成功')
                                    this.refreshList()
                                } else {
                                    this.$message.error(res.msg)
                                }
                            })
                        } else {
                            this.$axios(this.api.dyyg.pubTopicUpdateId, this.inputForm, "post").then((res) => {
                                if (res.status) {
                                    this.visible = false;
                                    this.$message.success('修改成功')
                                    this.refreshList()
                                } else {
                                    this.$message.error(res.msg)
                                }
                            })
                        }
                    }
                })
            },
            // 删除
            del(id) {
                let info = '确定删除所选项吗?'
                this.$axios(this.api.dyyg.getTopicOnCollection + id, {}, 'get').then(res => {
                    if (res.status && res.data) {
                        info = '确定删除所选项吗?该栏目存在关联藏品'
                    }
                    this.$confirm(info, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.loading = true
                        this.$axios(this.api.dyyg.pubTopicRemoveById + id, {}, 'delete').then(res => {
                            this.loading = false
                            if (res.status) {
                                this.$message.success('删除成功');
                                this.pageNo = 0
                                this.pageSize = 10
                                this.refreshList();
                            } else {
                                this.$message(res.msg);
                            }
                        })
                    })
                })

            },
            // 获取上传图片数据
            getDataList(data) {
                if (data.response) {
                    let obj = data.response.data
                    this.inputForm.image = obj.path
                } else {
                    this.inputForm.image = ''
                }
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
        }
    }
</script>

<style scoped>

</style>
